<template>
  <element-query @on-small-change="reset">
    <div slot-scope="{ width, eqSmall }">
      <div class="fcn-oferta">
        <div class="top-menu" v-if="eqSmall">
          <div class="carousel">
            <hooper :settings="hooperSettings" @slide="selectedOffer=$event.currentSlide">
              <slide v-for="(offer, index) in offers"
                     :key="`x-${index}`"
                     @click="selectedOffer=index"
                     class="fcn-oferta--menu"
                     :class="{ 'fcn-oferta--menu-selected': selectedOffer === index }">
                <div class="fcn-oferta--title">
                  {{ offer.title }}
                </div>
                <div class="fcn-oferta--price">
                  {{ offer.price.netto }}
                </div>
              </slide>
              <hooper-navigation slot="hooper-addons"></hooper-navigation>
            </hooper>
          </div>
        </div>
        <div class="fcn-oferta__wrapper" :class="`fcn-oferta--offers-${currentOffers.length}`">
          <div class="ee"></div>
          <template v-if="!eqSmall">
            <div v-for="(offer, index) in currentOffers" :key="`oferta-${index}`"
                 class="fcn-oferta--title"
                 :class="[`fcn-oferta-grid-cell-${index+1}-${0}`]"
                 >
              {{ offer.title }}
            </div>
            <!--<div class="">
            </div>-->
            <div v-for="(offer, index) in currentOffers" :key="`oferta-cena-${index}`"
                 class="fcn-oferta--price"
                 :class="[`fcn-oferta-grid-cell-${index+1}-${1}`]">
              {{ offer.price.netto }}
            </div>
          </template>

            <template v-for="(feature, featureIndex) in features" >
              <div class="fcn-oferta--feature"
                   :class="[ { [feature.id]: true },
                   { 'fcn-oferta--feature--odd' : !(featureIndex % 2) }]"
                   :key="featureIndex">{{ feature.label }}
                    <small v-if="feature.info">{{ feature.info }}</small>
              </div>
              <div class="fcn-oferta--feature-value"
                   v-for="(offer, offerIndex) in currentOffers"
                   :class="[`fcn-oferta-grid-cell-${offerIndex + 1}-${featureIndex + 2}`,
                   { 'fcn-oferta--feature--odd' : !(featureIndex % 2), [feature.id]: true }]"
                   :key="`${featureIndex}-${offerIndex}`">
                <div class="no-mode-translate-demo-wrapper">
                  <transition name="no-mode-translate-fade">
                <div :key="`${featureIndex}-${offerIndex}-${offer.features[feature.id]}`">
                  <div class="inner">
                  <template v-if="feature.id === 'submit'">
                    <a
                       @click="$emit('selected-offer', {
                         offer: offer.id,
                         title: offer.title,
                         mobile: eqSmall,
                         popup: actions[offer.id].popup,
                         href: actions[offer.id].href })"
                       class="fcn-oferta--button cta cta-button
                       border-2px border--red-o bg--red-o color--white cta-button--bold">
                      {{ offer.features[feature.id] }}
                    </a>
                    <small v-if="offer.features.notes" v-html="offer.features.notes"/>
                  </template>
                  <template v-else-if="offer.features[feature.id]">
                    <div v-html="offer.features[feature.id]" />
                  </template>
                  <template v-else>
                    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><defs></defs><title>x</title><polygon class="cls-1" points="16 2.24 13.76 0 8 5.76 2.24 0 0 2.24 5.76 8 0 13.76 2.24 16 8 10.24 13.76 16 16 13.76 10.24 8 16 2.24"/></svg>
                  </template>
                  </div>
                  </div>
                  </transition>
                </div>
              </div>
            </template>
        </div>
      </div>
    </div>
  </element-query>
</template>

<script>
import {
  Hooper,
  Slide,
  Navigation as HooperNavigation,
} from 'hooper';
import 'hooper/dist/hooper.css';
import ElementQuery from './ElementQuery';

export default {
  name: 'FcnOferta',
  props: {
    actions: {
      type: Object,
    },
  },
  components: {
    Hooper,
    Slide,
    HooperNavigation,
    ElementQuery,
  },
  eq: {
    breakpoints: {
      small: { maxWidth: 600 },
      medium: { minWidth: 601 },
    },
  },
  data() {
    return {
      hooperSettings: {
        itemsToShow: 1,
        centerMode: true,
      },
      selectedOffer: null,
      features: [
        { id: 'pakiet_minut', label: 'Pakiet minut' },
        { id: 'numery_miejskie', label: 'Numery miejskie' },
        { id: 'konta_telefoniczne', label: 'Konta telefoniczne (SIP)' },
        { id: 'numer_wewnetrzny', label: 'Numer wewnętrzny ' },
        { id: 'poczta_glosowa', label: 'Poczta głosowa' },
        { id: 'ivr_menu', label: 'IVR (menu)' },
        { id: 'wirtualny_fax', label: 'Wirtualny fax' },
        {
          id: 'funkcje_centrali',
          label: 'Funkcje centrali',
          info: 'Kolejkowanie połączeń, Wybierz\n'
            + 'wewnętrzny, Przekieruj, Warunek,\n'
            + 'Odtwórz komunikat, Nagraj wiadomość',
        },
        { id: 'synteza_mowy', label: 'Synteza mowy' },
        { id: 'submit', label: '' },
      ],
      offers: [
        {
          id: 'fcn250',
          title: 'FCN 250',
          price: { netto: '20 zł + VAT', brutto: '' },
          features: {
            pakiet_minut: '250',
            konta_telefoniczne: '1',
            poczta_glosowa: 'TAK',
            numery_miejskie: '1',
            submit: 'Wybierz',
          },
        },
        {
          id: 'fcn500',
          title: 'FCN 500',
          price: { netto: '35 zł + VAT', brutto: '' },
          features: {
            pakiet_minut: '500',
            konta_telefoniczne: '2',
            numery_miejskie: '2',
            poczta_glosowa: 'TAK',
            wirtualny_fax: '1',
            submit: 'Wybierz',
          },
        },
        {
          id: 'fcn1000',
          title: 'FCN 1000',
          price: { netto: '60 zł + VAT', brutto: '' },
          features: {
            pakiet_minut: '1000',
            konta_telefoniczne: '3',
            numery_miejskie: '3',
            poczta_glosowa: 'TAK',
            wirtualny_fax: '1',
            submit: 'Wybierz',
          },
        },
        {
          id: 'fcnnolimit',
          title: 'FCN No limit',
          price: { netto: '95 zł + VAT', brutto: '' },
          features: {
            pakiet_minut: '<span>bez limitu</span>',
            konta_telefoniczne: '3',
            numery_miejskie: '3',
            poczta_glosowa: 'TAK',
            wirtualny_fax: '1',
            submit: 'Wybierz',
          },
        },
        {
          id: 'wirtualna_centrala_m',
          title: 'Wirtualna Centrala “M”',
          price: { netto: '59 zł + VAT', brutto: '' },
          features: {
            pakiet_minut: '500',
            konta_telefoniczne: '3 + 1(fax)',
            numery_miejskie: '3',
            numer_wewnetrzny: '10',
            poczta_glosowa: 'TAK',
            ivr_menu: '1',
            wirtualny_fax: '1',
            funkcje_centrali: '6',
            synteza_mowy: 'nielimitowana',
            submit: 'Wybierz',
          },
        },
        {
          id: 'wirtualna_centrala_l',
          title: 'Wirtualna Centrala “L”',
          price: { netto: '99 zł + VAT', brutto: '' },
          features: {
            pakiet_minut: '1000',
            konta_telefoniczne: '5 + 1(fax)',
            numery_miejskie: '5',
            numer_wewnetrzny: '20',
            poczta_glosowa: 'TAK',
            ivr_menu: '2',
            wirtualny_fax: '1',
            funkcje_centrali: '12',
            synteza_mowy: 'nielimitowana',
            submit: 'Wybierz',
          },
        },
        {
          id: 'wirtualna_centrala_xl',
          title: 'Wirtualna Centrala “XL”',
          price: { netto: '189 zł + VAT', brutto: '' },
          features: {
            pakiet_minut: '2000',
            konta_telefoniczne: '10 + 1(fax)',
            numery_miejskie: '10',
            numer_wewnetrzny: '30',
            poczta_glosowa: 'TAK',
            ivr_menu: '3',
            wirtualny_fax: '1',
            funkcje_centrali: '18',
            synteza_mowy: 'nielimitowana',
            submit: 'Wybierz',
          },
        },
      ],
    };
  },
  methods: {
    reset(newValue) {
      if (newValue) {
        this.selectedOffer = 0;
      } else {
        this.selectedOffer = null;
      }
    },
  },
  computed: {
    currentOffers() {
      if (this.selectedOffer !== null) {
        return [this.offers[this.selectedOffer]];
      }
      return [...this.offers];
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.fcn-oferta {
  font-family: Arial, sans-serif;
  display: block;
  .fcn-oferta--button {
    cursor: pointer;
    border-radius: 50px;
    display: inline-block;
  }
  .submit .inner small {
    display: block;
    line-height: 1.3;
    margin-top: 0.5rem;
    margin-right: 1rem;
    margin-left: 1rem;
  }
  .fcn-oferta--menu {
    padding: 0.4rem 0;
    font-size: 14px;
  }
  .fcn-oferta--title {
    // align-self: auto;
    display: grid;
    // justify-self: auto;
    align-items: flex-end;
    // text-align: center;
    justify-items: center;
    font-weight: bold;
    color: #39b54a;
    padding-top: 0.5rem;
    text-align: center;
  }
  .fcn-oferta--price {
    color: #808690;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    text-align: center;
  }
  .fcn-oferta--menu-selected {
    font-weight:bold;
    background: White;
    border-bottom: 1px solid #eee;
    color: #39b54a;
  }
  /*
  .hooper {
    height: auto;
  }
  */

  &__wrapper {
    display: grid;
    grid-template-columns: 180px 1fr;
    grid-template-rows: 1fr 1fr;
    grid-auto-rows: minmax(min-content, max-content);
    grid-column-gap: 1px;
    grid-row-gap: 0;
    justify-items: stretch;
    align-items: stretch;
    &.fcn-oferta--offers-1 {
      grid-template-columns: 180px 1fr;
      grid-template-areas: "ee ee" "ee ee";
    }
    &.fcn-oferta--offers-2 {
      grid-template-columns: 180px 1fr 1fr;
      grid-template-areas: "ee . ." "ee . .";
    }
    &.fcn-oferta--offers-3 {
      grid-template-columns: 180px 1fr 1fr 1fr;
      grid-template-areas: "ee . . ." "ee . . .";
    }
    &.fcn-oferta--offers-4 {
      grid-template-columns: 180px 1fr 1fr 1fr 1fr;
      grid-template-areas: "ee . . . ." "ee . . . .";
    }
    &.fcn-oferta--offers-5 {
      grid-template-columns: 180px 1fr 1fr 1fr 1fr 1fr;
      grid-template-areas: "ee . . . ." "ee . . . .";
    }
    &.fcn-oferta--offers-6 {
      grid-template-columns: 180px 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-template-areas: "ee . . . ." "ee . . . .";
    }
    &.fcn-oferta--offers-7 {
      grid-template-columns: 170px 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-template-areas: "ee . . . ." "ee . . . .";
    }
    .ee {
      grid-area: ee;
    }
    > div {
      background: rgb(247, 247, 247);
      text-align: center;
      padding: 5px;
      border-left: 1px solid #eee;
    }
    .fcn-oferta--title {
      // align-self: auto;
      display: grid;
      // justify-self: auto;
      align-items: flex-end;
      // text-align: center;
      justify-items: center;
      font-weight: bold;
      color: #39b54a;
      padding-top: 0.5rem;
      text-align: center;
    }
    .fcn-oferta--price {
      color: #808690;
      font-size: 16px;
      line-height: 24px;
      font-weight: 700;
      text-align: center;
    }
    .fcn-oferta--feature {
      color: #808690;
      font-size: 14px;
      text-align: left;
      font-weight: normal;
      min-height: 2rem;
      display: grid;
      align-items: center;
    }
    .fcn-oferta--feature-value {
      color: rgb(128, 134, 144);
      font-size: 14px;
      font-weight: bold;
      display: grid;
      align-items: center;
      position: relative;
      justify-items: center;
      svg {
        width: 16px;
        margin: 0 auto;
        opacity: 0.6;
      }
      &.pakiet_minut {
        color: #39b54a;
      }
    }
    .fcn-oferta--feature--odd {
      background: White;
    }
  }

}

.no-mode-translate-demo-wrapper {
  // position: absolute;
  /* min-height: 50%; */
  // top: 0;
  // bottom: 0;
  // left: 0;
  // right: 0;
  display: grid;
}
.no-mode-translate-demo-wrapper > div {
  position: absolute;
  display: grid;
  width: 100%;
  // width: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.fcn-oferta .submit {
  min-height: 6rem !important;
}

.no-mode-translate-demo-wrapper .inner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap:wrap;
}

.no-mode-translate-fade-enter-active, .no-mode-translate-fade-leave-active {
  transition: all 0.2s;
}
.no-mode-translate-fade-enter, .no-mode-translate-fade-leave-active {
  opacity: 0;
}
.no-mode-translate-fade-enter {
  transform: translateX(52px);
}
.no-mode-translate-fade-leave-active {
  transform: translateX(-52px);
}
.cls-1{fill:#c1272d;}


$cols: (1,2,3,4,5,6,7,8);
$rows: (1,2,3,4,5,6,7,8,9,10,11,12,13,14);
@each $col in $cols {
  @each $row in $rows {
    .fcn-oferta-grid-cell-#{$col}-#{$row} {
      -ms-grid-column: #{$col};
      -ms-grid-row: #{$row};
    }
  }
}
</style>
<style lang="scss">
.fcn-oferta {
  .hooper {
    height: auto !important;

    .hooper-list {
      height: auto !important;
    }

    .hooper-track {
      height: auto !important;
    }
  }
}
.fcn-oferta .submit {
  min-height: 7rem;
}
</style>
