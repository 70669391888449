var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('element-query',{on:{"on-small-change":_vm.reset},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var width = ref.width;
var eqSmall = ref.eqSmall;
return _c('div',{},[_c('div',{staticClass:"fcn-oferta"},[(eqSmall)?_c('div',{staticClass:"top-menu"},[_c('div',{staticClass:"carousel"},[_c('hooper',{attrs:{"settings":_vm.hooperSettings},on:{"slide":function($event){_vm.selectedOffer=$event.currentSlide}}},[_vm._l((_vm.offers),function(offer,index){return _c('slide',{key:("x-" + index),staticClass:"fcn-oferta--menu",class:{ 'fcn-oferta--menu-selected': _vm.selectedOffer === index },on:{"click":function($event){_vm.selectedOffer=index}}},[_c('div',{staticClass:"fcn-oferta--title"},[_vm._v(" "+_vm._s(offer.title)+" ")]),_c('div',{staticClass:"fcn-oferta--price"},[_vm._v(" "+_vm._s(offer.price.netto)+" ")])])}),_c('hooper-navigation',{attrs:{"slot":"hooper-addons"},slot:"hooper-addons"})],2)],1)]):_vm._e(),_c('div',{staticClass:"fcn-oferta__wrapper",class:("fcn-oferta--offers-" + (_vm.currentOffers.length))},[_c('div',{staticClass:"ee"}),(!eqSmall)?[_vm._l((_vm.currentOffers),function(offer,index){return _c('div',{key:("oferta-" + index),staticClass:"fcn-oferta--title",class:[("fcn-oferta-grid-cell-" + (index+1) + "-" + (0))]},[_vm._v(" "+_vm._s(offer.title)+" ")])}),_vm._l((_vm.currentOffers),function(offer,index){return _c('div',{key:("oferta-cena-" + index),staticClass:"fcn-oferta--price",class:[("fcn-oferta-grid-cell-" + (index+1) + "-" + (1))]},[_vm._v(" "+_vm._s(offer.price.netto)+" ")])})]:_vm._e(),_vm._l((_vm.features),function(feature,featureIndex){
var _obj;
return [_c('div',{key:featureIndex,staticClass:"fcn-oferta--feature",class:[ ( _obj = {}, _obj[feature.id] = true, _obj ),
                 { 'fcn-oferta--feature--odd' : !(featureIndex % 2) }]},[_vm._v(_vm._s(feature.label)+" "),(feature.info)?_c('small',[_vm._v(_vm._s(feature.info))]):_vm._e()]),_vm._l((_vm.currentOffers),function(offer,offerIndex){
                 var _obj;
return _c('div',{key:(featureIndex + "-" + offerIndex),staticClass:"fcn-oferta--feature-value",class:[("fcn-oferta-grid-cell-" + (offerIndex + 1) + "-" + (featureIndex + 2)),
                 ( _obj = { 'fcn-oferta--feature--odd' : !(featureIndex % 2) }, _obj[feature.id] = true, _obj )]},[_c('div',{staticClass:"no-mode-translate-demo-wrapper"},[_c('transition',{attrs:{"name":"no-mode-translate-fade"}},[_c('div',{key:(featureIndex + "-" + offerIndex + "-" + (offer.features[feature.id]))},[_c('div',{staticClass:"inner"},[(feature.id === 'submit')?[_c('a',{staticClass:"fcn-oferta--button cta cta-button\n                     border-2px border--red-o bg--red-o color--white cta-button--bold",on:{"click":function($event){return _vm.$emit('selected-offer', {
                       offer: offer.id,
                       title: offer.title,
                       mobile: eqSmall,
                       popup: _vm.actions[offer.id].popup,
                       href: _vm.actions[offer.id].href })}}},[_vm._v(" "+_vm._s(offer.features[feature.id])+" ")]),(offer.features.notes)?_c('small',{domProps:{"innerHTML":_vm._s(offer.features.notes)}}):_vm._e()]:(offer.features[feature.id])?[_c('div',{domProps:{"innerHTML":_vm._s(offer.features[feature.id])}})]:[_c('svg',{attrs:{"id":"Layer_1","data-name":"Layer 1","xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 16 16"}},[_c('defs'),_c('title',[_vm._v("x")]),_c('polygon',{staticClass:"cls-1",attrs:{"points":"16 2.24 13.76 0 8 5.76 2.24 0 0 2.24 5.76 8 0 13.76 2.24 16 8 10.24 13.76 16 16 13.76 10.24 8 16 2.24"}})])]],2)])])],1)])})]})],2)])])}}])})}
var staticRenderFns = []

export { render, staticRenderFns }